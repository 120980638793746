import { onMount } from 'solid-js';
import type { AuthUser } from '../api';
import { getAnalytics } from './analytics';

export interface Props {
  user?: AuthUser;
}

export function AnalyticsTracker(props: Props) {
  const analytics = getAnalytics();

  onMount(() => {
    window.addEventListener('load', () => {
      if (props.user) {
        analytics.identify(props.user?.id);
      }

      analytics.startTrackingProperties();
    });
  });

  return;
}
